<template>
    <div class="wizard-item">
        <div class="content__header" style="padding-top: 20px;">
                <div class="content__header-left">
                    <div class="filter-options__search">
                        <input type="search" placeholder="Search" class="input bg-left search-icon" v-model="search">
                    </div>
                </div>
                <div class="content__header-right">
					<WhitePlusButton @click="addRow">{{ $translate('add-cadastral-parcel') }}</WhitePlusButton>
                </div>
            </div>
        <div style="width: 100%;margin: 0;" v-if="!loading">
            <div class="table-wrapper" style="width: 100%;padding: 0;">
                <table class="table editable_table" :key="tableKey">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th v-for="(columnName, index) in tableColumns" :key="index">
                                <div class="table-filter">
                                    <span>{{ columnName }}</span>
                                </div>
                            </th>
                            <th class="text-end">
                                <div class="table-filter">
                                    <span>{{ $translate('actions') }}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="(row, rowIndex) in filteredItems" :key="rowIndex">
                            <td>{{ row[0] }}</td>
                            <td 
                                v-for="(columnData, columnIndex) in [...filteredItems[rowIndex]].splice(1, filteredItems[rowIndex].length)" 
                                :key="columnIndex" contenteditable="true" @blur="setCellContent($event, row[0], columnIndex)"
                            >
                                {{ columnData }}
                            </td>
                            <td class="text-end">
                                <div class="table-actions flex items-center justify-end">
                                    <button type="button" class="btn btn-icon red-color" @click="deleteRow(row[0])">
                                        <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Pagination v-model="tableItems" :items="tableData" class="pagination_not_fixed" />
        </div>
    </div>
</template>

<script>
import Base from "@/components/base";
import Buttons from "@/components/buttons";

export default {
    components: {
        ...Base,
        ...Buttons,
    },
    props: ['landRegisterInfo'],
    data() {
        return {
            tableColumns: ['Country', 'City', 'Sheet land registry', 'Particle land registry', 'Area land registry'],
            tableData: [],
            tableKey:0,
            tableItems: [],
            search: '',
            loading: true,
        }
    },
    computed: {
        filteredItems(){
            return this.tableItems.filter(item => item.join('').includes(this.search));
        }
    },
    methods: {
        setCellContent(event, rowID, columnIndex) {
            this.tableData[this.tableData.findIndex(item => item[0] === rowID)][columnIndex+1] = event.target.innerText;
            this.tableKey++;
            this.$emit('landRegisterInfoChange', this.tableData);
        },
        deleteRow(rowID) {
            this.tableData.splice(this.tableData.findIndex(item => item[0] === rowID), 1);
            this.tableKey++;
            this.$emit('landRegisterInfoChange', this.tableData);
        },
        addRow() {
            let newRow = [!this.tableData.length ? this.tableData.length + 1 : +(this.tableData[this.tableData.length-1][0]) + 1, ...Array(this.tableColumns.length).fill("")];
            this.tableData.push(newRow);
            this.tableKey++;
            this.$emit('landRegisterInfoChange', this.tableData);
        },
    },
    created(){
        if(this.landRegisterInfo) {
            this.tableData = this.landRegisterInfo;
        }
        setTimeout(() => {
           this.loading = false; 
        }, 300);
    }
}
</script>