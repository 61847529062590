<template>
    <div class="wrapper_main farm-map-page" :class="{ 'footer-fixed':addObjectState, 'processing':loading }" style="min-height: 100dvh;">
        <main class="content">
            <div class="content__header border-none">
                <div class="content__header-left no-wrap flex-fill" v-show="!addObjectState">
                    <h1 class="heading-page-h1">{{ $translate('farm-map') }}</h1>
                    <div class="categories filter centered-dropdown dropdown-wrapper" v-click-outside="closeFilters">
                        <div class="flex items-center">
                            <button class="btn btn-w-shadow btn-standart dropdown-btn" @click="filtersShow = !filtersShow" :class="{ 'active':filtersShow }" style="padding: 0 8px;">
                                <SvgIcons icon="filter-icon.svg" styles="margin-right: 10px;" /> {{ $translate('filter') }}
                            </button>
                        </div>
                        <div class="categories__dropdown dropdown-content" :class="{ 'active':filtersShow }">
                            <span class="heading__dropdown">{{ $translate('filters') }}</span>
                            <ul>
                                <li v-for="(item, index) in filtersArray" :key="index">
                                    <label class="module__check">
                                        <input type="checkbox" v-model="filtersArrayActive" :value="item">
                                        <span class="check"></span>
                                        <span class="text">{{ item }}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="content__header-left" v-if="addObjectState && !loading && !!addedObjectType">
                    <div class="headline">
                        <h1 class="headline__title">{{ editPaddock ? 'Edit' : 'Add' }}
                            <span>{{ addTabs.find(item => item.value === addedObjectType).title[0] }} 
                                <span class="hidden-xs" v-if="addTabs.find(item => item.value === addedObjectType).title[1]">
                                    {{ addTabs.find(item => item.value === addedObjectType).title[1] }}
                                </span>
                            </span>
                        </h1>
                        <p class="headline__text">
                            {{ $translate('draw-and-describe-your') }} <span>{{ addTabs.find(item => item.value === addedObjectType).title[0].toLowerCase() }} 
                                <span v-if="addTabs.find(item => item.value === addedObjectType).title[1]">
                                    {{ addTabs.find(item => item.value === addedObjectType).title[1].toLowerCase() }}
                                </span>
                            </span> {{ $translate('on-map') }}
                        </p>
                    </div>
                </div>
                <div class="content__header-right" v-show="!addObjectState">
                    <div class="buttons-group add-buttons">
                        <button 
                            v-for="(item, index) in addTabs" :key="index" 
                            type="button" class="btn btn-w-shadow btn-standart-2 w-auto-xs" @click="addObjectInMap(item.value)"
                        >
                            <SvgIcons icon="add-iot-icon.svg" />
                            <span>{{ $translate('add') }} {{ item.title[0] }} <span class="hidden-xs" v-if="item.title[1]">{{ item.title[1] }}</span></span>
                        </button>
                    </div>
                </div>
                <!--  :class="{ 'pointer-events-none':drawType }" -->
                <div class="content__header-right" v-show="addObjectState && addedObjectType">
                    <div class="buttons-group margin-btn flex-auto" v-show="!editPaddock && (addedObjectType !== 'herd' && addedObjectType !== 'tool_equipment')">
                        <button class="btn btn-w-shadow btn-standart-2" :class="{ 'active':drawType === 'POLYGON' }" @click="changeDrawType('POLYGON')">
                            <SvgIcons icon="definition-icon.svg" />
                            <span class="blue-sky">{{ $translate('borders-definition') }}</span>
                        </button>
                        <button class="btn btn-w-shadow btn-standart-2" :class="{ 'active':drawType === 'CIRCLE' }" @click="changeDrawType('CIRCLE')">
                            <SvgIcons icon="privot-aria-icon.svg" />
                            <span>{{ $translate('pivot-area') }}</span>
                        </button>
                    </div>
                    <div class="buttons-group margin-btn flex-auto" v-show="!editPaddock && (addedObjectType === 'herd' || addedObjectType === 'tool_equipment')">
                        <button class="btn btn-w-shadow btn-standart-2" :class="{ 'active':drawType === 'MARKER' }" @click="changeDrawType('MARKER')">
                            <SvgIcons icon="add-point-icon.svg" />
                            <span class="blue-sky">{{ $translate('add-point') }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="tabs-wrapper" v-show="addObjectState">
                <ul class="tabs">
                    <li class="tabs__item" v-for="(item, index) in tabs" :key="index">
                        <a class="nav-tab" @click="tabActive = index" :class="{ 'active':tabActive === index }">
                            {{ item }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <!-- map -->
                <div class="tab-content__item" v-if="addedObjectType !== 'tool_equipment'" :class="{ 'active':tabActive === 0 }">
                    <div class="content__body" :class="{ 'addition':addObjectState }">
                        <div class="map search-map" style='border-radius: var(--border-radius-7); overflow: hidden;'>
                            <!-- <img src="img/farm-map-map1.jpg" style="max-width:100%;width:100%;"> -->
                            <div id="map" :style="{ width: '100%', height: mapHeight }"></div>
                            <div class="zoom-control">
                                <a class="zoom-control-in">
                                    <SvgIcons icon="zoom-control-in.svg" />
                                </a>
                                <a class="zoom-control-out">
                                    <SvgIcons icon="zoom-control-out.svg" />
                                </a>
                            </div>
                            <a class="maptype-control">
                                <SvgIcons icon="maptype-control.svg" />
                            </a>
                            <div class="search_input_wrpr">
                                <input 
                                    type="text" placeholder="Search" class="input bg-left search-icon" 
                                    v-model="searchAdress" @input="geocodeForward" :class="{ 'bb_0':searchAdress.length }"
                                >
                                <ul v-show="searchAdress.length">
                                    <template v-if="searchResult.length">
                                        <li v-for="(item, index) in searchResult" :key="index">
                                            <a @click="setPosFromSearch({ lat: item.latitude, lng: item.longitude })">{{ item.label }}</a>
                                        </li>
                                    </template>
                                    <li v-else>
                                        <a>{{ $translate('address-not-found') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- basic-info -->
                <div class="tab-content__item" :class="{ 'active': addedObjectType === 'tool_equipment' ? tabActive === 0 : tabActive === 1 }" v-if="addedObjectType">
                    <div class="wizard-item" v-if="!loading">
                        <div class="items-group cards">
                            <template v-for="(item, index) in basicInfoConfig[addedObjectType]">
                                <div 
                                    v-if="item.id !== 'description' && item.id !== 'plots_config' && !item.show_from_field_fill_eq" 
                                    class="cards__col-6" :key="index+'-'+updateKeyNumber"
                                >
                                    <div class="el-form">
                                        <template v-if="item.id !== 'disposal_date' || (item.id === 'disposal_date' && basicInfo['disposal'])">
                                            <label class="el-form__title" v-if="item.name">{{ item.name }}</label>
                                            <DatePicker v-model="basicInfo[item.id]" v-if="item.datepicker" />
                                        </template>

                                        <Dropdown
                                            v-if="item.options"
                                            :options="item.options_from ? fieldsData[item.options_from] : item.options"
                                            :current="basicInfo[item.id] === 'Custom animal' ? 'Other' : basicInfo[item.id]"
                                            :name="item.id"
                                            @change="updateBasicInfo"
                                            :colorpicker="item.id === 'colour_on_map'"
                                            :class="{ 'bg-left search-icon':item.options_from }"
                                            :search="item.options_from ? true : false"
                                        />

                                        <YearPicker v-if="item.yearpicker" v-model="basicInfo[item.id]" @updateKeyNumber="updateKeyNumber++" />

                                        <input
                                            v-if="
                                                item.id !== 'plots_config' && item.id !== 'description' && 
                                                item.id !== 'variety' && !item.options && !item.checkboxes && 
                                                !item.radio && !item.datepicker && !item.yearpicker &&
                                                !item.attach && !item.photo_attach
                                            "
                                            :type="item.type ? item.type : 'text'" class="input" :placeholder="item.name"
                                            v-model="basicInfo[item.id]"
                                        >
                                        <div v-if="item.show_field_when_fill && basicInfo[item.id] === basicInfoConfig[addedObjectType].find(itemF => itemF.id === item.show_field_when_fill).show_from_field_fill_eq">
                                            <input type="text" class="input mt-10" v-model="basicInfo[item.show_field_when_fill]">
                                        </div>

                                        <label class="file_input_box input flex items-center" v-if="item.attach">
                                            <input type="file" @change="addFile($event, item.id)" multiple style="display: none;">
                                            <div class="file_name" :class="{ 'placeholder':!basicInfo[item.id].length }">
                                                {{ basicInfo[item.id].length ? getFileName(basicInfo[item.id][0]) : 'Attach File' }}
                                            </div>
                                            <button class="delete-gallery__item btn" @click.stop="downloadFile(basicInfo[item.id][0])" v-if="basicInfo[item.id].length && editPaddock" style="right: 78px;">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" style="width: 15px;height: 15px;" viewBox="0 0 36.375 36.376" xml:space="preserve">
                                                    <g>
                                                        <path fill="#9AA6AC" d="M33.938,25.626v8.25c0,1.383-1.119,2.5-2.5,2.5h-26.5c-1.381,0-2.5-1.117-2.5-2.5v-8.25c0-1.381,1.119-2.5,2.5-2.5   s2.5,1.119,2.5,2.5v5.75h21.5v-5.75c0-1.381,1.119-2.5,2.5-2.5S33.938,24.245,33.938,25.626z M16.42,27.768   c0.488,0.488,1.129,0.732,1.768,0.732c0.643,0,1.279-0.244,1.77-0.732l7.5-7.498c0.978-0.975,0.978-2.558,0-3.535   c-0.977-0.977-2.561-0.977-3.535,0l-3.231,3.232V2.5c0-1.381-1.119-2.5-2.5-2.5s-2.5,1.119-2.5,2.5v17.467l-3.232-3.232   c-0.977-0.977-2.561-0.977-3.535,0c-0.977,0.978-0.977,2.56,0,3.535L16.42,27.768z"/>
                                                    </g>
                                                </svg>
                                            </button>
                                            <button class="delete-gallery__item btn" @click.stop="deleteFileFromInput(item.id)" v-if="basicInfo[item.id].length">
                                                <SvgIcons icon="x.svg" />
                                            </button>

                                            <div class="icon_wrpr">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="752pt" height="752pt" version="1.1" viewBox="0 0 752 752" style="width: 30px;height:30px;">
                                                    <path d="m288.86 568.27c-29.363 0-58.723-10.418-81.93-31.258-24.152-21.785-38.359-52.094-40.254-84.77-1.4219-32.676 9.4727-63.934 31.73-88.086l156.28-172.38c6.1562-7.1055 17.523-7.5781 24.152-0.94531 7.1055 6.6289 7.5781 17.523 0.94531 24.152l-156.28 172.38c-15.629 17.523-23.68 39.781-22.73 63.461 0.94531 23.207 11.367 44.988 28.887 60.617 35.52 32.203 90.453 29.363 123.13-5.2109l183.28-197.48c9.9453-10.418 15.156-24.152 14.68-38.832-0.47266-14.207-6.6289-27.941-17.523-37.414-10.418-9.4727-24.152-14.68-38.832-14.207-14.207 0.47266-27.469 6.6289-37.414 17.523l-169.54 186.59c-3.7891 4.2617-5.6836 9.4727-5.2109 15.156 0.47266 5.6836 2.8398 10.418 7.1055 14.207 8.5234 7.1055 20.363 6.1562 27.941-1.8945l143.5-152.96c6.6289-7.1055 17.523-7.1055 24.152-0.94531 7.1055 6.6289 7.1055 17.523 0.94531 24.152l-143.49 153.43c-19.891 21.312-53.043 23.207-75.301 4.7344-11.367-9.4727-18.469-23.207-19.418-38.359-0.94531-15.156 3.7891-29.363 14.207-40.727l170.02-186.59c16.102-17.523 37.414-27.469 61.094-28.887 23.68-0.94531 45.938 7.1055 63.461 23.207s27.469 37.887 28.414 61.566c0.94531 23.68-7.5781 46.41-23.68 63.461l-182.8 197.48c-23.68 26.047-56.832 38.832-89.508 38.832z"/>
                                                </svg>
                                            </div>
                                        </label>
                                        
                                        <div v-if="item.photo_attach">
                                            <div class="item-body" style="margin: 0;">
                                                <div class="gallery" style="grid-template-columns: repeat(1, 1fr);" v-if="basicInfo[item.id].length">
                                                    <div class="gallery__item" v-for="(image, imageIndex) in basicInfo[item.id]" :key="image.url ? image.url : image">
                                                        <img :src="image.url ? image.url : imagesForShow.length ?  imagesForShow.find(imagesForItem => imagesForItem.key === image).url : null" alt="" />
                                                        <button 
                                                            class="delete-gallery__item btn" data-target="delete-media" 
                                                            @click.prevent="deleteImage(imageIndex, item.id)"
                                                        >
                                                            <SvgIcons icon="x.svg" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div 
                                                class="fake-dropzone" 
                                                :class="{ 'drop-ready': dragState }" 
                                                @drop.prevent.stop="dropImage($event, item.id)"  
                                                @dragenter.prevent @dragover.prevent
                                                style="padding: 0;"
                                            >
                                                <label style="padding: 30px;">
                                                    <img src="@/assets/img/upload-image-icon.svg">
                                                    <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                                                    <input type="file" @change="addFile($event, item.id)" multiple>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group__row" v-if="item.checkboxes || item.radio">
                                            <div
                                                class="form-group__col-5" :class="{'mb-15':item.checkboxes}"
                                                v-for="(checkboxItem, checkboxIndex) in (item.checkboxes ? item.checkboxes : item.radio)" :key="checkboxItem + '-' + checkboxIndex"
                                            >
                                                <div class="el-form">
                                                    <label class="module__check" v-if="item.checkboxes">
                                                        <input type="checkbox" name="category" :value="checkboxItem" v-model="basicInfo[item.id]">
                                                        <span class="check"></span>
                                                        <span class="text">{{ checkboxItem }}</span>
                                                    </label>

                                                    <div class="base-radio" v-else>
                                                        <input 
                                                            class="base-radio__input" :value="checkboxItem" type="radio" 
                                                            :id="item.id+'-'+checkboxIndex" :name="item.id" v-model="basicInfo[item.id]"
                                                        >
                                                        <label class="base-radio__label" :for="item.id+'-'+checkboxIndex">{{ checkboxItem }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="item.id === 'plots_config'">
                                    <div class="cards__col-6" :key="index+'-crop-'+updateKeyNumber">
                                        <div class="el-form">
                                            <label class="el-form__title">{{ $translate('crop') }}</label>
                                            <div>
                                                <Dropdown
                                                    class="bg-left search-icon"
                                                    :options="fieldsData.crop"
                                                    :current="basicInfo.plots_config.crop" 
                                                    :name="'plots_config.crop'"
                                                    @change="($event)=> {
                                                        basicInfo.plots_config.crop = $event.target.value;
                                                        updateKeyNumber++;
                                                    }"
                                                    :search="true"
                                                />
                                                <input 
                                                    type="text" class="input mt-10" placeholder="Other crop"
                                                    v-if="basicInfo.plots_config.crop === 'Other'"
                                                    v-model="basicInfo.plots_config.cropOther"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards__col-6" :key="index+'-variety-'+updateKeyNumber">
                                        <div class="el-form">
                                            <label class="el-form__title">{{ $translate('variety') }}</label>
                                            <input type="text" class="input" v-model="basicInfo.plots_config.variety">
                                        </div>
                                    </div>
                                    <div class="cards__col-6" :key="index+'-crop-year-'+updateKeyNumber">
                                        <div class="el-form">
                                            <label class="el-form__title">{{ $translate('crop-year') }}</label>
                                            <YearPicker v-model="basicInfo.plots_config.crop_year" @updateKeyNumber="updateKeyNumber++" />
                                        </div>
                                    </div>
                                </template>
                                <template v-if="item.id === 'description'">
                                    <div class="cards__col-12" :key="index+'-description-'+updateKeyNumber">
                                        <div class="el-form">
                                            <label class="el-form__title" v-if="item.name">{{ item.name }}</label>
                                            <textarea
                                                
                                                v-if="item.id === 'description'" v-model="basicInfo[item.id]" 
                                                class="textarea h-200" :placeholder="$translate('write-description-here...')"
                                            ></textarea>
                                        </div>
                                    </div>
                                </template>
                                <div class="cards__col-12" v-if="item.hr" :key="index+'-hr-'+updateKeyNumber">
                                    <hr class="gray_hr" :class="{'mt-20':item.id === 'description'}">
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- Field Land Register -->
                <div class="tab-content__item" :class="{ 'active':tabActive === 2 }" v-if="addedObjectType === 'field' && tabActive === 2">
                    <LandRegister 
                        :landRegisterInfo="initialZoneData && initialZoneData.land_register.length ? initialZoneData.land_register : landRegisterInfo" 
                        @landRegisterInfoChange="landRegisterInfo = $event" 
                    />
                </div>
                <!-- Field Soil -->
                <div class="tab-content__item" :class="{ 'active':tabActive === 3 }" v-if="addedObjectType === 'field' && tabActive === 3">
                    <FieldSoil :soilConfig="soilConfig" :soilInfo="soilInfo" :fieldsData="fieldsData" :editPaddock="editPaddock" @changeSoilMap="soilInfo.soil_map = $event" />
                </div>
                <!-- Assign Devices -->
                <div class="tab-content__item" :class="{ 'active': addedObjectType === 'field' ? tabActive === 4 : tabActive === 2 }" v-if="addedObjectType !== 'tool_equipment'">
                    <AssignDevices @updateDevices="addedDevices = $event" :editPaddock="editPaddock" :addedObjectType="addedObjectType" />
                </div>
                <!-- Assign Paddock / Building -->
                <div class="tab-content__item" :class="{ 'active': tabActive === 3 }" v-if="addedObjectType === 'herd' && tabActive === 3 && addedObjectType !== 'tool_equipment'">
                    <AssignPaddockBuilding @updateChildPaddock="childPaddock = $event" :childPaddock="childPaddock" :addedObjectType="addedObjectType" />
                </div>
            </div>
        </main>

        <div class="pagination wizard-actions fixed" v-show="addObjectState">
            <div class="pagination__body">
                <button type="button" class="btn btn-w-shadow btn-standart-2" @click="stepControl(true)">{{ $translate('cancel') }}</button>
                <div class="pagination_right_btn_group">
                    <button type="button" class="btn btn-primary btn-standart" v-show="editPaddock" @click="savePaddockChange()">
                        {{ $translate('save') }}
                    </button>
                    <button type="button" class="btn btn-primary btn-standart icon-rigth" @click="stepControl()" v-show="tabActive !== tabs.length-1">
                        {{ $translate('next') }}
                        <SvgIcons icon="Arrow-next.svg" />
                    </button>
                    <button type="button" class="btn btn-primary btn-standart" v-show="tabActive === tabs.length-1 && !editPaddock" @click="createPaddockObject()">
                        {{ $translate('add') }} {{ addedObjectType ? addTabs.find(item => item.value === addedObjectType).title[0] : '' }}
                        <span class="hidden-xs" v-if="addedObjectType && addTabs.find(item => item.value === addedObjectType).title[1]">
                            {{ addTabs.find(item => item.value === addedObjectType).title[1] }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import GMap from "@/provider/GMap.js";
import Base from "@/components/base";
import { API, Storage } from 'aws-amplify';
import {
	createFarm,
    createPaddock,
    updatePaddock,
    updateDevice,
} from '@/graphql/mutations';
import farmConfig from '@/config/farm';
import tabs from './tabs/';

export default {
    components: {
        ...Base,
        ...tabs,
    },
    data(){
        return {
            basicInfoConfig: farmConfig.basicInfoConfig,
            soilConfig: farmConfig.soilConfig,
            fieldsData: farmConfig.fieldsData,
            loading: true,
            filtersShow: false,
            filtersArray: ['Tool / Equipment', 'Herd Zones', 'Building Zones', 'Infrastructure Zones', 'Field Zones', 'Paddock Zones',],
            filtersArrayActive: [],
            searchAdress: '',
            searchResult: [],
            fromInit: null,
            addedObjectType: null,
            addObjectState: false,
            tabs: ['Map', 'Basic Info', 'Assign Devices'],
            tabActive: 0,
            addTabs: [
                {
                    title: ['Tool', '/ Equipment'], value: 'tool_equipment'
                },
                {
                    title: ['Field'], value: 'field'
                },
                {
                    title: ['Building', '/ Infrastructure'], value: 'building'
                },
                {
                    title: ['Paddock'], value: 'paddocks'
                },
                {
                    title: ['Animal'], value: 'herd'
                },
            ],
            mapHeight: 'calc(100vh - 161px)',
            basicInfo: {},
            soilInfo: {},
            landRegisterInfo: [],
            drawType: null,
            addedFigure: null,
            updateKeyNumber: 0,
            addedDevices: [],
            editPaddock: null,
            childPaddock: null,
            initialZoneData: null,
            dragState:false,
            imagesForShow: [],
            farmData: null,
        }
    },
    computed: {
        devices(){
            return this.$store.state.devices;
        },
        paddocks(){
            return this.$store.state.paddocks;
        },
        computedFilters(){
            let filtersObject = {
                'Tool / Equipment': 'tool_equipment',
                'Herd Zones': 'herd',
                'Building Zones': 'building',
                'Infrastructure Zones': 'infrastructure',
                'Field Zones': 'field',
                'Paddock Zones': 'paddocks',
            };
            return Object.entries(filtersObject).filter(item => this.filtersArrayActive.includes(item[0])).map(item => item[1]);
        }
    },
    watch:{
        async computedFilters(){
            this.loading = true;
            await this.initFarm();
            this.loading = false;
        }
    },
    methods: {
        dropImage(ev, targetId){
            const event = { target: ev.dataTransfer }
            this.addFile(event, targetId)
            this.dragState = false;
        },
		dragOver(ev) {
			this.dragState = true;
		},
        addFile(ev, targetId) {
            [...ev.target.files].forEach((file) => {
                this.basicInfo[targetId] = [];
                this.basicInfo[targetId].push({
                    url: URL.createObjectURL(file),
                    file: file,
                });
            });
            this.updateKeyNumber++;
        },
        async deleteImage(imageIndex, targetId){
            const options={title:`Delete media?`,size:'sm',okLable:"Confirm",cancelLabel:"Cancel"} 
            await this.$dialogs.confirm('', options).then(async res=>{
                if(res.ok){
                    this.basicInfo[targetId].splice(imageIndex, 1);
                }
            });
            this.updateKeyNumber++;
        },
        deleteFileFromInput(targetId){
            this.basicInfo[targetId] = [];
            this.updateKeyNumber++;
        },
        async downloadFile(file){
            let link = document.createElement("a");
            link.download = file;
            link.target = "_blank";

            let downloadLink = null;
            let imagesRequests= [file].map(async key=>{
                return {url:await Storage.get(key)}
            })
            await Promise.all(imagesRequests).then(res=> downloadLink = res);

            link.href = downloadLink[0].url;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        },
        getFileName(fileObject){
            if(fileObject.file){
                return fileObject.file.name;
            } else if(fileObject && this.editPaddock){
                return fileObject;
            } else {
                return 'Attached File'
            }
        },
        async uploadFilesForBasicInfo(){
            for (let index = 0; index < this.basicInfoConfig[this.addedObjectType].length; index++) {
                let basicInfoField = this.basicInfo[this.basicInfoConfig[this.addedObjectType][index].id];
                if(this.basicInfoConfig[this.addedObjectType][index].photo_attach && basicInfoField.length) {
                    let images = [];
                    let imagesRequests = basicInfoField.map(async (img) => {
                        let name = null;
                        if(img.file){
                            name = `/images/${img.url.split("/")[img.url.split("/").length - 1]}`;
                            const s3File = await Storage.put(name, img.file);
                            this.basicInfo[this.basicInfoConfig[this.addedObjectType][index].id] = [s3File.key];
                        }
                    });

                    await Promise.all(imagesRequests);
                }

                if(this.basicInfoConfig[this.addedObjectType][index].attach && basicInfoField.length) {
                    const file = basicInfoField[0].file;
                    if(file && (basicInfoField[0] !== file.name)) {
                        try {
                            const s3File = await Storage.put(file.name, file);

                            this.basicInfo[this.basicInfoConfig[this.addedObjectType][index].id] = [s3File.key];
                        } catch (error) {
                            console.log("Error uploading file: ", error);
                        }
                    }
                }
            }
        },
        closeFilters(){
            this.filtersShow = false;
        },
        async initMap() {
			let payload = {
                lat: 45.6219172254747,
                lng: 9.284690169503811,
                type_filters: this.computedFilters
            };

            if(this.addedObjectType !== "tool_equipment"){
                this.fromInit = this.$provider.gmap.createMap('map', payload, (res) => {
                    const event = {
                        target: {
                            name: 'lat_lng',
                            value: res
                        }
                    }
                }, true, [...this.$store.state.paddocks].filter(item => 
                    (item.type === 'building' && this.computedFilters.includes(item.basic_info.type.toLowerCase())) || 
                    (item.type !== 'building' && this.computedFilters.includes(item.type))
                ));
            }

            this.$provider.gmap.on('addDrawing', data => {
                this.setAddedFigureData(data);

                this.fromInit !== null ? this.fromInit.drawingManager.setDrawingMode(null) : null;
            })
            
            this.$provider.gmap.on('editDrawing', data => {
                this.setAddedFigureData(data);
            })
            
            this.$provider.gmap.on('editMapDrawingObjects', async data => {
                if(!this.$route.query.edit || this.$route.query.edit !== data.id) {
                    this.$router.push({ query: { 'edit': data.id } });
                }

                this.mapHeight = 'calc(100vh - 333px)';

                this.addedDevices = [];

                this.editPaddock = data;
                this.basicInfo = data.basic_info;
                this.addedFigure = data.map_info;
                this.addedObjectType = data.type ? data.type : 'paddocks';
                if(this.addedObjectType === 'herd') {
                    this.tabs = ['Map', 'Basic Info', 'Assign Devices', 'Assign Paddock / Building / Field'];
                    this.childPaddock = data.child_paddock;
                } else if(this.addedObjectType === 'tool_equipment') {
                    this.tabs = ['Basic Info'];
                } else if(this.addedObjectType === 'field'){
                    this.tabs = ['Map', 'Basic Info', 'Land Register', 'Soil', 'Assign Devices'];
                    this.soilInfo = data.soil;
                    // if(data.soil.soil_map.length){
                    //      let imagesRequests = data.soil.soil_map.map(async key => {
                    //         return { url:await Storage.get(key) }
                    //     })
                    //     await Promise.all(imagesRequests).then(res=> data.soil.soil_map = res)
                    // }
                } else {
                    this.tabs = ['Map', 'Basic Info', 'Assign Devices'];
                }
                
                this.devices.forEach(element => {
                    if(element.paddockID === this.editPaddock.id && !this.addedDevices.includes(element.id)) {
                        this.addedDevices.push(element.id);
                    }
                });

                for (let index = 0; index < this.basicInfoConfig[this.addedObjectType].length; index++) {
                    let basicInfoField = this.basicInfo[this.basicInfoConfig[this.addedObjectType][index].id];

                    if(this.basicInfoConfig[this.addedObjectType][index].photo_attach && basicInfoField.length) {
                        let itemForImagePush = {
                            key: null,
                            url: null,
                        }

                        basicInfoField.forEach(async item => {
                            let imagesRequests = [{ url:await Storage.get(item) }];

                            let urlGet = await Promise.all(imagesRequests).then(res => res);

                            itemForImagePush = {
                                key: item,
                                url: urlGet[0].url
                            }

                            if(!this.imagesForShow.includes(itemForImagePush)) this.imagesForShow.push(itemForImagePush)
                        })
                    }
    
                    if(this.basicInfoConfig[this.addedObjectType][index].attach && basicInfoField.length) {
                        let fileRequests = basicInfoField.map(async key=>{
                            return { url:await Storage.get(key) }
                        })

                        let resultFileRequests = null;
                        await Promise.all(fileRequests).then(res => resultFileRequests = res);
                    }
                }

                // this.tabActive = 1;
                this.addObjectState = true;
                // this.setAddedFigureData(data);
                this.initialZoneData = JSON.parse(JSON.stringify(data));
            })
		},
        setAddedFigureData(data) {
            if(data.type === "polygon") {
                if('data_emited' in data){
                    this.addedFigure = {
                        type: data.type,
                        points: [...data.data_emited.points],
                    }
                    if('total_area' in  this.basicInfo) this.basicInfo.total_area = data.data_emited.total_area;
                } else {
                    if('total_area' in  this.basicInfo) this.basicInfo.total_area = this.fromInit.google.maps.geometry.spherical.computeArea(data.getPath()).toFixed(2) +' m2';

                    this.addedFigure = {
                        type: data.type,
                        points: [],
                    }

                    for (let index = 0; index < data.getPath().getArray().length; index++) {
                        this.addedFigure.points.push({ 
                            lat: JSON.parse(JSON.stringify(data.getPath().getArray()[index].lat())), 
                            lng: JSON.parse(JSON.stringify(data.getPath().getArray()[index].lng())) 
                        });
                    }
                }
            }

            if(data.type === "circle") {
                if('total_area' in  this.basicInfo) this.basicInfo.total_area = (Math.PI * Math.pow(data.radius, 2)).toFixed(2) + ' m2';

                this.addedFigure = {
                    center_lat: data.center.lat(),
                    center_lng: data.center.lng(),
                    type: data.type,
                    radius: data.radius,
                }
            }

            if(data.type === "marker") {
                this.addedFigure = {
                    center_lat: data.position.lat(),
                    center_lng: data.position.lng(),
                    type: data.type,
                }
            }
        },
        async geocodeForward() {
            this.searchResult = [];
			const { data } = await this.$provider.positionStack.getForward(this.searchAdress);
			if(data.length) {
				const { 
					latitude, 
					longitude,
				} = data[0];
                this.searchResult = data;
				// this.$provider.gmap.setCords('company-map', { lat: latitude, lng: longitude })
			}
		},
        setPosFromSearch(cords){
            this.$provider.gmap.setCords('map', cords, true);
            this.searchResult = [];
            this.searchAdress = '';
        },
        addObjectInMap(type){
            if(type === 'herd') {
                this.tabs = ['Map', 'Basic Info', 'Assign Devices', 'Assign Paddock / Building / Field'];
            } else if(type === 'tool_equipment') {
                this.tabs = ['Basic Info'];
            } else if(type === 'field'){
                this.tabs = ['Map', 'Basic Info', 'Land Register', 'Soil', 'Assign Devices'];
                for (let index = 0; index < this.soilConfig.length; index++) {
                    this.soilInfo[this.soilConfig[index].id] = '';
                    if(this.soilConfig[index].id === 'soil_map'){
                        this.soilInfo[this.soilConfig[index].id] = [];
                    }
                }
            } else {
                this.tabs = ['Map', 'Basic Info', 'Assign Devices'];
            }

            this.basicInfo = {};
            for (let index = 0; index < this.basicInfoConfig[type].length; index++) {
                this.basicInfo[this.basicInfoConfig[type][index].id] = '';

                if(this.basicInfoConfig[type][index].id === 'variety'){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = [''];
                }

                if(this.basicInfoConfig[type][index].id === 'crop'){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = [];
                }

                if(this.basicInfoConfig[type][index].id === 'plots_config'){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = {
                        crop: '',
                        cropOther: '',
                        variety: '',
                        crop_year: ''
                    };
                }

                if(this.basicInfoConfig[type][index].photo_attach || this.basicInfoConfig[type][index].attach){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = [];
                }
            }

            this.addedObjectType = type;

            this.tabActive = 0;
            this.addObjectState = true;
            this.mapHeight = 'calc(100vh - 333px)';

            if(type === 'herd' || type === 'tool_equipment') {
                this.fromInit.drawingManager.setDrawingMode(this.fromInit.google.maps.drawing.OverlayType.MARKER);
                this.drawType = 'MARKER';
            } else {
                this.fromInit.drawingManager.setDrawingMode(this.drawType ? 
                    this.fromInit.google.maps.drawing.OverlayType[this.drawType] : 
                    this.fromInit.google.maps.drawing.OverlayType.POLYGON
                );
                this.drawType = 'POLYGON';
            }
        },
        async stepControl(cancel = false){
            if(cancel){
                this.loading = true;

                this.$router.replace({ query: null });
                this.tabActive = 0;
                this.addObjectState = false;
                this.mapHeight = 'calc(100vh - 161px)';
                this.basicInfo = {};
                this.$provider.gmap.editObject = this.addedObjectType = this.editPaddock = this.drawType = null;

                this.fromInit ? this.fromInit.drawingManager.setDrawingMode(null) : null;
                await this.initFarm();
                
                setTimeout(() => {
                    this.loading = false;
                }, 2000);
                
                return false;
            }

            if(this.tabActive !== (this.addedObjectType === 'herd' ? 3 : this.addedObjectType === "field" ? 4 : 2)) {
                this.tabActive++;
            }
        },
        updateBasicInfo(ev, targetChange) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;

            if(this.addedObjectType === "herd" && value === "Other" && name === "type"){
                this.basicInfo[name] = 'Custom animal';
            } else {
                this.basicInfo[name] = value;
            }


            this.updateKeyNumber++;
        },
        changeDrawType(type){
            this.drawType = type;
            this.fromInit.drawingManager.setDrawingMode(this.fromInit.google.maps.drawing.OverlayType[this.drawType]);
        },
        async generateSoilMap(){
            if(this.soilInfo.soil_map && this.soilInfo.soil_map.length){
                let images = [];
                let imagesRequests = this.soilInfo.soil_map.map(async (img) => {
                    let name = null;
                    if(this.editPaddock){
                        if(typeof img === 'string'){
                            images.push(img);
                            return img;
                        } else {
                            name = `/images/${img.url.split("/")[img.url.split("/").length - 1].split('?')[0]}`;
                            images.push(name);
                            if(!this.soilInfo.soil_map.includes(name)){
                                const s3File = await Storage.put(name, img.file);
                                return s3File.key;
                            }
                        }
                    } else {
                        name = `/images/${img.url.split("/")[img.url.split("/").length - 1]}`;
                        images.push(name);
                        const s3File = await Storage.put(name, img.file);
                        return s3File.key;
                    }
                });

                if(this.editPaddock) {
                    await Promise.all(imagesRequests);
                } else {
                    await Promise.all(imagesRequests).then((res) => images = res);
                }

                this.soilInfo.soil_map = images;
            }
        },
        async createPaddockObject(){
            this.loading = true;

            await this.generateSoilMap();
            await this.uploadFilesForBasicInfo();

            let inputObject = {
                type: this.addedObjectType,
                basic_info: this.basicInfo,
                farmID: this.$store.state.farm.id,
                map_info: { ...this.addedFigure },
                child_paddock: this.childPaddock,
            }

            if(this.addedObjectType === 'field') {
                inputObject = { 
                    ...inputObject, 
                    soil: this.soilInfo, 
                    land_register: this.landRegisterInfo, 
                };
            }

            delete inputObject.map_info.__typename;

            let createdPaddock = await API.graphql({
                query: createPaddock,
                variables: {
                    input: inputObject
                }
            });

            this.addedDevices.forEach(deviceId => {
                let findedDevice = this.devices.find(item => item.id === deviceId);

                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: findedDevice.id,
                            paddockID: createdPaddock.data.createPaddock.id
                        }
                    }
                });
            });

            this.stepControl(true);

            this.loading = false;
        },
        async savePaddockChange(){
            this.loading = true;

            // удаление девайса с паддока
            this.devices.forEach(deviceItem => {
                if(!this.addedDevices.includes(deviceItem.id) && deviceItem.paddockID === this.editPaddock.id){
                    API.graphql({
                        query: updateDevice,
                        variables: {
                            input: {
                                id: deviceItem.id,
                                paddockID: null
                            }
                        }
                    });
                }
            });

            await this.generateSoilMap();
            await this.uploadFilesForBasicInfo();

            let inputObject = JSON.parse(JSON.stringify({
                id: this.editPaddock.id,
                type: this.addedObjectType,
                basic_info: this.basicInfo,
                farmID: this.$store.state.farm.id,
                map_info: this.addedFigure,
                child_paddock: this.childPaddock,
            }));

            if(this.addedObjectType === 'field') {
                inputObject = { 
                    ...inputObject, 
                    soil: this.soilInfo, 
                    land_register: this.landRegisterInfo, 
                };
            }

            for (let key in inputObject) {
                let subObj = inputObject[key];
                
                for (let subKey in subObj) {
                    for (let subSubKey in subObj[subKey]) {
                        if(subSubKey === '__typename'){
                            delete subObj[subKey][subSubKey]
                        }
                    }

                    if(subKey === '__typename'){
                        delete subObj[subKey]
                    }
                }
            }

            for (let index = 0; index < inputObject.map_info.points.length; index++) {
                delete inputObject.map_info.points[index].__typename;
            }

            await API.graphql({
                query: updatePaddock,
                variables: {
                    input: inputObject
                }
            });

            // сохранение девайсов в паддок
            this.addedDevices.forEach(deviceId => {
                let findedDevice = this.devices.find(item => item.id === deviceId);
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: findedDevice.id,
                            paddockID: this.editPaddock.id
                        }
                    }
                });
            });

            this.stepControl(true);

            this.loading = false;
        },
        async initFarm(){
            if(this.$route.query.edit){
                this.addObjectState = true;
            }

            if(this.$store.state.companyData && this.$store.state.companyData.id){
                let farm = await this.$store.dispatch('GET_FARM');
                this.farmData = farm;
                if(!farm){
                    await API.graphql({
                        query: createFarm,
                        variables: {
                            input: {
                                companyID: this.$store.state.companyData.id
                            }
                        }
                    });

                    await this.$store.dispatch('GET_FARM');
                }
                await this.$store.dispatch('GET_PADDOCKS');
                await this.$store.dispatch('GET_DEVICES');

                setTimeout(async () => {
                    await this.initMap();
                }, 1000);
            } else {
                setTimeout(() => {
                    this.initFarm();
                }, 1000);
            }


            if(this.$route.query.edit){
                this.$provider.gmap.editObject = this.$route.query.edit;

                let findEditPaddock = [...this.$store.state.paddocks].find(item => item.id === this.$route.query.edit);
                if(findEditPaddock && findEditPaddock.type === "tool_equipment"){
                    this.tabs = ['Basic Info'];
                    
                    this.editPaddock = findEditPaddock;
                    this.basicInfo = findEditPaddock.basic_info;
                    this.addedObjectType = findEditPaddock.type ? findEditPaddock.type : 'paddocks';
                    this.addObjectState = true;
                }
            }

            if(this.$route.query.add){
                setTimeout(() => {
                    this.addObjectInMap(this.$route.query.add);
                }, 1000);
            }
        }
    },
    created(){
        this.tabs = ['Map', 'Basic Info', 'Assign Devices'];
        this.filtersArrayActive = [...this.filtersArray];
    },
    async mounted(){
        await this.initFarm();
        this.loading = false;
    }
}
</script>