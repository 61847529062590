<template>
    <div class="addition">
        <div class="flex content-flex-heading">
            <div class="headline headline--sm">
                <h2 class="headline__title">{{ $translate('assign-paddock-/-building-/-field') }}</h2>
                <p class="headline__text">{{ $translate('here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your') }}
                    <span v-if="addedObjectType">{{ typeName[0].toLowerCase() }} 
                        <span v-if="typeName[1]">
                            {{ typeName[1].toLowerCase() }}
                        </span>
                    </span>
                    <span v-else>{{ $translate('zone') }}</span>
                </p>
            </div>
        </div>

        <ul class="editable-list">
            <li class="editable-list__item" v-for="item in selectedPaddock" :key="item.id">
                <div class="data-row">
                    <ul class="data-row__list">
                        <li class="data-row__item">{{ item.basic_info.name }}</li>
                        <li class="data-row__item">{{ item.basic_info.total_area }} m²</li>
                    </ul>
                    <div class="data-row__controls">
                        <button type="button" class="btn btn-icon red-color" @click="remove">
                            <inline-svg :src="require(`@/assets/img/ico-remove.svg`)" />
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <button type="button" class="btn btn-w-shadow btn-standart-2" @click="changePaddockShow">
            <span>{{ selectedPaddock.length ? $translate('change') : $translate('select') }} {{ $translate('paddock-/-building') }}</span>
        </button>

        <ChangePaddockBuildingPopup
			v-if="popupData.show"
			:paddocks="popupData.paddocks"
			@close="closePopup"
			@submit="submitPopup"
			:title="(selectedPaddock.length ? $translate('change') : $translate('select')) + ' ' + $translate('paddock-/-building')"
		/>

        <!-- before translate :title="`${ selectedPaddock.length ? 'Change' : 'Select' } Paddock / Building`" -->
    </div>
</template>

<script>
import ChangePaddockBuildingPopup from "@/components/popups/ChangePaddockBuildingPopup";

export default {
    components: {
        ChangePaddockBuildingPopup,
    },
    props: ['childPaddock', 'addedObjectType'],
    data(){
        return {
            popupData: {
                show: false,
                paddocks: []
            },
            selectPaddockId: null,
            typeNames: [
                {
                    title: ['Field'], value: 'field'
                },
                {
                    title: ['Building', '/ Infrastructure'], value: 'building'
                },
                {
                    title: ['Paddock'], value: 'paddocks'
                },
                {
                    title: ['Herd'], value: 'herd'
                },
            ],
        }
    },
    computed: {
        paddocks(){
            return this.$store.state.paddocks.filter(item => item.type === "paddocks" || item.type === "building" || item.type === "field");
        },
        selectedPaddock(){
            return this.paddocks.filter(item => item.id === this.selectPaddockId)
        },
        typeName(){
            if(this.addedObjectType){
                return this.typeNames.find(item => item.value === this.addedObjectType).title
            }
        },
    },
    methods: {
        changePaddockShow(){
            this.popupData = {
                show: true,
                paddocks: this.paddocks,
            };
        },
        closePopup(){
            this.popupData = {
                show: false,
                paddocks: []
            };
        },
        submitPopup(submitedPaddock){
            // console.log('submitedPaddock:', submitedPaddock);
            this.selectPaddockId = submitedPaddock;
            this.closePopup();
            this.$emit('updateChildPaddock', this.selectPaddockId);
        },
        remove(){
            this.selectPaddockId = null;
            this.$emit('updateChildPaddock', this.selectPaddockId);
        }
    },
    created(){
        if(this.childPaddock) this.selectPaddockId = this.childPaddock;
    }
}
</script>