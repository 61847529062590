<template>
    <div class="addition" :class="loading ? 'processing' : ''">
        <div class="flex content-flex-heading">
            <div class="content__header none-border">
                <div class="content__header-left">
                    <div class="headline headline--sm">
                        <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                        <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your') }} 
                            <span v-if="addedObjectType">{{ typeName[0].toLowerCase() }} 
                                <span v-if="typeName[1]">
                                    {{ typeName[1].toLowerCase() }}
                                </span>
                            </span>
                            <span v-else>{{ $translate('zone') }}</span>
                        </p>
                    </div>
                </div>
                <div class="content__header-right">
                    <div class="buttons-group margin-btn flex-auto">
                        <button type="button" class="btn btn-w-shadow btn-standart-2" @click="addDeviceOpen">
                            <SvgIcons icon="add-iot-icon.svg" />
                            <span>{{ $translate('add-devices') }}</span>
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <ul class="editable-list">
            <li class="editable-list__item" v-for="(item, index) in devicesListShow" :key="index">
                <div class="data-row">
                    <ul class="data-row__list">
                        <li class="data-row__item">{{ item.name }}</li>
                        <li class="data-row__item">
                            <span class="data-row__icon"><DeviceIcon :category="item.category"></DeviceIcon></span> {{ item.category }}
                        </li>
                        <li class="data-row__item">{{ item.location && item.location.address ? item.location.address : item.location }}</li>
                    </ul>
                    <div class="data-row__controls">
                        <button type="button" class="btn btn-icon red-color" @click="removeDeviceFromList(item.id)">
                            <SvgIcons icon="ico-remove.svg" />
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <div class="buttons-group margin-btn flex-auto">
            <button type="button" class="btn btn-w-shadow btn-standart-2" @click="addDeviceOpen">
                <SvgIcons icon="add-iot-icon.svg" />
                <span>{{ $translate('add-devices') }}</span>
            </button>
        </div>

        <AddDevice
			v-if="addDevicesPopupInfo.show"
			:devices="addDevicesPopupInfo.devices"
			@close="addDeviceClose"
			@submit="addDeviceSubmit"
			:title="$translate('assign-devices')"
		/>
        <!-- before translate title="Assign Device(s)" -->
    </div>
</template>

<script>
import AddDevice from "@/components/popups/AddDevice";
import Base from "@/components/base";

export default {
    props: ['editPaddock', 'addedObjectType'],
    components: {
        AddDevice,
        ...Base,
    },
    data(){
        return {
            loading: false,
            addedDevices: [],
            addDevicesPopupInfo: {
                show: false,
                devices: null
            },
            typeNames: [
                {
                    title: ['Field'], value: 'field'
                },
                {
                    title: ['Building', '/ Infrastructure'], value: 'building'
                },
                {
                    title: ['Paddock'], value: 'paddocks'
                },
                {
                    title: ['Herd'], value: 'herd'
                },
            ],
        }
    },
    watch: {
        editPaddock(){
            if(this.editPaddock){
                this.addedDevices = this.devices.filter(item => item.paddockID === this.editPaddock.id).map(item => item.id);
            }
        }
    },
    computed: {
        devices() {
			return this.$store.state.devices
		},
        devicesListShow(){
            return this.devices.filter(item => this.addedDevices.includes(item.id));
        },
        typeName(){
            if(this.addedObjectType){
                return this.typeNames.find(item => item.value === this.addedObjectType).title
            }
        },
    },
    methods: {
        //add device control
		addDeviceOpen(){
			this.addDevicesPopupInfo.show = true;
            let devicesData = JSON.parse(JSON.stringify(this.devices));
			// this.addDevicesPopupInfo.devices = devicesData.filter(item => !this.addedDevices.includes(item.id) && !item.paddockID);
			this.addDevicesPopupInfo.devices = devicesData.filter(item => !this.addedDevices.includes(item.id));
        },
        addDeviceClose(){
			this.addDevicesPopupInfo.show = false;
			this.addDevicesPopupInfo.devices = null;
        },
        addDeviceSubmit(devices){
            devices.forEach(element => {
               this.addedDevices.push(element);
            });
            this.$emit('updateDevices', this.addedDevices);
			// this.addFieldItems('devices',devices)
			this.addDeviceClose()
        },
        removeDeviceFromList(itemId){
            this.addedDevices.splice(this.addedDevices.findIndex(item => item === itemId), 1);
            this.$emit('updateDevices', this.addedDevices);
        },
        async getDevices(){
            this.loading = true;
            await this.$store.dispatch("GET_DEVICES");
            this.loading = false;
        }
    },
    async created(){
        this.getDevices();
    }
}
</script>

<style>

</style>