<template>
    <button type="button" class="btn btn-primary btn-standart" @click="action">
        <SvgIcons icon="add-iot-icon.svg" />
        <span><slot/></span>
    </button>
</template>

<script>
import SvgIcons from "@/components/base/SvgIcons.vue";

export default {
	name: 'BluePlusButton',
    components: {
        SvgIcons,
    },
    props: ['to'],
    methods:{
        action(){
            if(this.to){
                this.$router.push({path:this.to})
            }
            if(this.$listeners.click){
                this.$emit('click')
            }
        }
    }
}
</script>
