<template>
    <div class="wizard-item">
        <div class="items-group cards">
            <div class="cards__col-12 el-form" style="margin-bottom: 20px;">
                <label class="el-form__title">{{ $translate('soil-map') }}</label>

                <div class="item-body" style="margin: 0 0 10px;" v-if="images.length">
                    <div class="gallery">
                        <div class="gallery__item" v-for="image in images" :key="image.url">
                            <img :src="image.url" alt="" />
                            <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="deleteImage(image.url)">
                                <inline-svg :src="require(`@/assets/img/x.svg`)" />
                            </button>
                        </div>
                    </div>
                </div>

                <div 
                    class="fake-dropzone" :class="{ 'drop-ready': dragState }"
                    @drop.prevent.stop="dropImage" @dragenter.prevent @dragover.prevent
                >
                    <label>
                        <img src="@/assets/img/upload-image-icon.svg" />
                        <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                        <input type="file" @change="addImage" multiple />
                    </label>
                </div>
            </div>
            <template v-for="(item, index) in soilConfig">
                <div
                    :class="[{ 'cards__col-group-3-item':item.input_group }, item.input_group ? `input_group_number_${item.input_group}` : '']" 
                    :key="index+'-'+updateKeyNumber" class="cards__col-6" v-if="!item.dropzone"
                    :style="{ padding: item.input_group === 1 ? '0 0 0 10px' : item.input_group === 3 ? '0 10px 0 0' : item.input_group ? '0' : '0 10px' }"
                >
                    <div class="el-form">
                        <label class="el-form__title" v-if="item.name">{{ item.name }}</label>
                        <Dropdown
                            v-if="item.options"
                            :options="item.options_from ? fieldsData[item.options_from] : item.options"
                            :current="soilInfo[item.id]" 
                            :name="item.id"
                            @change="($event)=> {
                                soilInfo[item.id] = $event.target.value;
                                updateKeyNumber++;
                            }"
                            :class="{ 'bg-left search-icon':item.options_from }"
                            :search="item.options_from ? true : false"
                        />
                        <input
                            v-if="!item.options && !item.checkboxes && !item.radio && !item.datepicker" 
                            :type="item.type ? item.type : 'text'" class="input" :placeholder="item.name" 
                            v-model="soilInfo[item.id]"
                        >
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Base from "@/components/base";
import { API, Storage } from 'aws-amplify';

export default {
    components: {
        ...Base,
    },
    props: ['soilConfig', 'soilInfo', 'fieldsData', 'editPaddock',],
    data(){
        return {
            updateKeyNumber: 0,
            images: [],
            dragState: false,
        }
    },
    methods: {
        dropImage(ev) {
            const event = { target: ev.dataTransfer };
            this.addImage(event);
            this.dragState = false;
        },
        dragOver(ev) {
            this.dragState = true;
        },
        addImage(ev) {
            [...ev.target.files].forEach((file) => {
                this.images.push({
                    url: URL.createObjectURL(file),
                    file: file,
                });
            });
            this.$emit('changeSoilMap', this.images);
        },
        async deleteImage(url) {
            const options = { title: `Delete media?`, size: "sm", okLable: "Confirm", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    let index = this.images.findIndex((i) => i.url == url);
                    if (index !== -1) {
                        this.images.splice(index, 1);
                    }
                }
            });
            this.$emit('changeSoilMap', this.images);
        },
    },
    async mounted(){
        if(this.editPaddock && this.soilInfo && this.soilInfo.soil_map && this.soilInfo.soil_map.length){
                let imagesRequests = this.soilInfo.soil_map.map(async key => {
                return { url:await Storage.get(key) }
            })
            await Promise.all(imagesRequests).then(res=> this.images = res)
        }
    }
}
</script>