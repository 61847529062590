<template>
    <button type="button" class="btn btn-w-shadow btn-standart-2" @click="action">
        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
        <span><slot/></span>
    </button>
</template>

<script>
export default {
	name: 'BluePlusButton',
	props: ['to'],
    methods:{
        action(){
            if(this.to){
                this.$router.push({path:this.to})
            }
            if(this.$listeners.click){
                this.$emit('click')
            }
        }
    }
}
</script>
