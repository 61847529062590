import FieldSoil from "./FieldSoil";
import AssignDevices from "./AssignDevices";
import AssignPaddockBuilding from "./AssignPaddockBuilding";
import LandRegister from "./LandRegister";

export default {
    FieldSoil,
    AssignDevices,
    AssignPaddockBuilding,
    LandRegister,
}