<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{title}}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
            </div>
            <div class="popup__window-body">
                <div class="items-group cards">
                    <label class="module__check check-input" >
                        <input type="checkbox" :checked="isCheckedAll" @change="toggleAllCheckedInputs" />
                        <span class="check"></span>
                    </label>
                    <div class="cards__col-4">
                        <form action="">
                            <input type="text" class="input bg-left search-icon" :placeholder="$translate('search')" v-model="rowsFilters.search" />
                        </form>
                    </div>
                    <DropdownAdvanced
                        :selectAll="$translate('all-categories')"
                        :options="devCats"
                        v-model="rowsFilters.category"
                    ></DropdownAdvanced>
                    <!-- before translate selectAll="All Categories" -->
                </div>
                <div class="data-row" v-for="d in filteredRows" :key="d.id">
                    <ul class="data-row__list">
                        <li class="data-row__item">
                            <label class="module__check">
                                <input type="checkbox" name="add-device" v-model="checkedInputs[d.id]"/>
                                <span class="check"></span>
                                <span class="text">
                                    <span class="data-row__icon">
                                        <DeviceIcon :category="d.category"></DeviceIcon>
                                    </span>
                                    {{d.name}}
                                </span>
                            </label>
                        </li>
                    </ul>
                </div>
                <PopupPagination v-model="filteredRows" :items="rawSource" />
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button type="button" class="btn btn-primary btn-standart-2" @click="submit" :class="{processing}">
                    <span>{{ $translate('submit') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import {mixTables} from "@/mixins";
import {devCats} from "@/config";
import PopupWrpr from './PopupWrpr';

export default {
    name: "AddDevice",
    components: {
        ...Base,
        PopupWrpr,
    },
    mixins:[mixTables],
    props: ["devices","title","processing"],
    data() {
        return {
            source:"devices",

            devCats:devCats
        };
    },

    computed: {
        sourceData(){
            return this.devices || []
        },
       

    },
    methods: {
        
        close() {
            this.$emit("close");
        },
        submit() {
             this.$emit("submit", this.getCheckedInputs());
        },
        changeCategory(ev){
            console.log(ev);
        },
    
      
    },
};
</script>
<style scoped>
    .check-input{
        margin-left: 20px;
    }
</style>